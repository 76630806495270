.template-list-filter-table-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.template-list-table-titles {
    color: #1A2744;
    font-weight: 600;
}

.template-list-table-filter-icon {
    color: #1A2744;
    cursor: pointer;
}

.template-list-responsive-card-details {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    color: white;
    border-radius: 7px;
    background-color: #17536B;
    padding: 5px;
}

.template-list-responsive-card-data {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}