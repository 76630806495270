.addComplementaryField_main_box {
    margin: 25px 0px 50px 0px;
    width: 80%
}

.addComplementaryField_subMain_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.addComplementaryField_side_border {
    flex: 1;
    border-top: 1px solid #AEAEB2;
}

@media(max-width: 600px) {

    .addComplementaryField_main_box {
        width: 100%
    }

}