.CP_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.CP_dialog_title {
    font-size: 21px;
    font-weight: 600;
    color: #16536B;
    font-family: 'Public Sans', sans-serif;
}

.CP_dialog_SubTitle {
    font-size: 15px;
    font-weight: 400;
    color: #414040;
    font-family: 'Public Sans', sans-serif;
}

.CP_input_title {
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #201E1E;
    line-height: 22px;
    margin-bottom: 6px;
    margin-left: 10px;
}

.CP_button_valider {
    font-family: 'Mulish', sans-serif;
    background-color: #16536B;
    color: white;
}

.CP_button_valider:hover {
    background-color: #16536B;
}