/* Add this to your CSS file */
@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

.drawer-enter {
    animation: slideIn 0.3s forwards;
}

.drawer-exit {
    animation: slideOut 0.3s forwards;
}