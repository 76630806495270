.select-temp-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 240px;
    min-height: 160px;
    border-radius: 8px;
    cursor: pointer;
    padding: 5px
}

@media(max-width: 700px) {

    .select-temp-card {
        flex-direction: column;
        margin: 2% auto;
        width: 90%;
        min-height: 100%;
    }
}