.copy-actions {
    display: flex;
    gap: 1%;
}

.copy-label {
    font-weight: bold;
    font-size: 20px;
}

.copy-copyfor-box {
    border: #34697E solid;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.copy-target-doctor {
    margin-top: 20px;
    border-radius: 25px;
    background-color: lavender;
}

.copyTemp-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.copyTemp-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.copyTemp-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.copyTemp-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.copyTemp-template-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.copyTemp-actions-box {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 40px;
}

.copyTemp-requiredNote{
    color: gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
}
@media(max-width: 500px) {
    .copy-label {
        font-size: 14px;
    }
}