.edit-temp-action-box {
    display: flex;
    gap: 5%;
}

.edit-temp-action-btn {
    color: #ffffff;
    background-color: #17536B;
}

.edit-temp-action-btn:hover {
    color: #ffffff;
    background-color: #17536B;
}

.edit-temp-loading {
    position: absolute;
    top: 55%;
    margin-left: 40%;
}

.edit-temp-field-label {
    flex-basis: 40%;
}

.edit-temp-field-input {
    flex-basis: 50%;
    border: 2px solid;
    border-color: #17536B;
    border-radius: 7px;
    padding: 5px;
}

.edit-temp-owners-btn-box {
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-around;
}

.edit-temp-edit-owner-btn {
    color: #ffffff;
    background-color: #17536B;
}

.edit-temp-sections-box {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.edit-temp-sections-typography {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 7px;
}

.edit-temp-sections-part {
    display: flex;
    align-items: center;
}

.edit-temp-sections-part > * {
    flex: auto;
}

.edit-temp-sections-remove-icon,
.edit-temp-sections-edit-icon {
    flex-shrink: 0;
}

.edit-onetemp-sections-box {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 5px;
}

.edit-onetemp-sections-style {
    display: flex;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    padding: 7px;
    color: #17536B;
    font-size: 14px;
    cursor: pointer;
}

.edit-temp-sections-remove-icon {
    cursor: pointer;
    color: red;
}

.edit-temp-sections-edit-icon {
    cursor: pointer;
    color: #17536B;
}

.edit-temp-sections-add-icon {
    padding: 10px;
    cursor: pointer;
    align-self: center;
    background-color: #17536B;
    color: #ffffff;
    height: auto;
    margin-left: 10px;
    border-radius: 7px;
}

.edit-temp-add-custom-field-btn {
    margin-right: 10px;
    color: #ffffff;
    background-color: #17536B;
}

.edit-temp-customfield-box {
    margin-top: 2%;
    display: flex;
    align-items: center;
    border: 1px solid #17536B;
    padding: 5px;
    border-radius: 7px;
}

.edit-onetemp-for-list-label-type-actions {
    display: flex;
    padding: 15px;
    border-radius: 10px;
    background-color: rgba(145, 158, 171, 0.12);
    width: 100%;
}

.edit-onetemp-for-list-label-type {
    display: flex;
    align-items: center;
    border: 1px solid #17536B;
    padding: 5px;
    border-radius: 7px;
    margin-top: 5px;
    width: 100%;
}

.edit-onetemp-for-list-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1%;
    gap: 5px
}

@media(max-width: 500px) {

    .edit-temp-action-btn {
        font-size: 11px;
        height: 3.5rem;
    }

    .edit-temp-owners-btn-box {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px;
    }

    .edit-temp-edit-owner-btn {
        font-size: 12px;
    }

    .edit-onetemp-for-list-label-type {
        flex-direction: column;
        align-items: flex-start;
    }

    .edit-temp-sections-typography {
        font-size: 14px;
        padding: 5px;
    }

}