.edit-tempowner-actions-box {
    display: flex;
    gap: 5%;
}

.edit-tempowner-owners-label {
    display: flex;
}

.edit-tempowner-owners-box {
    display: flex;
    flex-wrap: wrap;
}

.edit-tempowner-doctor-available-label {
    text-align: center;
    margin-top: 10px;
    font-size: 30px;
    font-family: sans-serif;
    font-weight: 800;
    color: #34697E;
}

.edit-tempowner-doctor-available-box {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2%;
}

.edit-tempowner-doctor-available-card {
    display: flex;
    justify-content: center;
    background-color: #2065D1;
    padding: 5px;
    margin: 1%;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    width: 100%;
}

.edit-tempowner-no-doctor-label {
    color: #34697E;
    text-align: center;

}

.editTempowner-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.editTempowner-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.editTempowner-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.editTempowner-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.editTempowner-info-box {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.editTempowner-field-info {
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
}

.editTempowner-field-label {
    flex-basis: 20%;
}

@media(max-width: 600px) {
    .edit-tempowner-doctor-available-label {
        font-size: 20px;
    }

    .editTempowner-field-label {
        flex-basis: 50%;
        font-size: 12px;
    }

    .editTempowner-field-response {
        font-size: 12px;
        flex-basis: 80%;
    }
}