.account-prof-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-prof-label {
    text-align: center;
    margin-bottom: 2%;
}

.account-prof-card {
    width: 100%;
}

.account-prof-card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.account-prof-card-pic-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.account-prof-card-dtails-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.account-prof-card-summary-skills-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 48%;
}

.account-prof-card-summary-typo{
    text-align: justify;
    line-height: 1.5;
}

.account-prof-card-skills-typo {
    text-align: center;
    width: 90%;
    padding: 10px;
    font-size: 17px;
    font-weight: bold;
}

.account-prof-card-pic-details-box {
    display: flex;
    width: 50%;
    height: 100%;
}

.account-prof-card-skills-box{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #17536B;
    border-radius: 10px;
    width: 90%;
}

.account-prof-card-skills-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.account-prof-card-skills-items {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
}

.account-prof-avatar-container {
    position: relative;
    display: inline-block;
    width: 180px;
    height: 150px;
    margin-bottom: 40px;
}

.account-prof-avatar {
    margin: 0 auto;
    height: 180px;
    width: 180px;
    z-index: 1;
}

.account-prof-avatar-icon {
    position: absolute;
    top: 95%;
    left: 83%;
    transform: translateX(-50%);
    z-index: 2;
    color: white;
    background-color: #000;
    border-radius: 50%;
    opacity: 0.8;
}

.account-prof-dlt-img-btn {
    border-radius: 50px;
    background-color: #84E1E6;
    margin-bottom: 20px;
}

.account-prof-avatar-delete-icon {
    position: absolute;
    top: 2%;
    left: 83%;
    transform: translateX(-50%);
    z-index: 2;
    color: white;
    background-color: #000;
    border-radius: 50%;
    opacity: 0.8;
}

.account-prof-cvDocument-delete-icon{
    color: white;
    background-color: #000;
    border-radius: 50%;
    opacity: 0.8;
}

.label-response-box {
    width: 90%;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.label-part {
    color: white;
    text-align: center;
    font-weight: bold;
    padding: 2%;
    border-radius: 7px;
    width: 100%;
    background-color: #17536B;

}

.response-part {
    text-align: center;
}

.phone-input {
    padding: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    flex-basis: 48%;
    margin-top: 12px;
}

@media(max-width: 900px) {
    .account-prof-card {
        width: 100%;
    }

    .account-prof-card-content {
        flex-direction: column;
        gap: 30px;
    }

    .account-prof-card-pic-details-box {
        width: 100%;
    }   

    .account-prof-card-skills-part {
        width: 100%;
    }

    .account-prof-card-summary-skills-box {
        width: 100%;
    }

    .account-prof-card-dtails-part {
        width: 50%;
    }
}

@media(max-width: 600px) {
    .account-prof-card-pic-details-box {
        align-items: center;
        width: 100%;
    }

    .account-prof-card-pic-part {
        width: 100%;
    }

    .account-prof-card-dtails-part {
        width: 100%;
    }

    .account-prof-card-pic-details-box {
        width: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .label-response-box {
        justify-content: flex-start;
    }
}