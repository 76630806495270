.fieldSelect_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.fieldSelect_dropdown_icon {
    width: 25px;
    height: 25px;
    color: #727179;
    cursor: pointer;
}

.fieldSelect_helper_text_box {
    padding: 8px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 8px 16px;
}