.home-navbar-main-box {
    display: flex;
}

.home-app-bar {
    background-color: white;
    position: fixed;
    display: flex;
    justify-content: center;
    height: 80px;
    offset: 0px, 4px;
    box-shadow: 10px 5px 6px rgba(0, 0, 0, 0.15);
}

.home-navbar-burgur-icon-btn {
    display: none;
    margin-right: 70px;
    font-size: 50px;
    background-color: #09C3CC;
    border-radius: 10px;
    width: 60px;
    height: 60px;
    padding: 8px;
    color: white;
}

.home-navbar-burgur-icon {
    font-size: 35px;
}

.home-imeah-navbar-logo {
    width: 200px;
}

.home-imeah-navbar-logo-links-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2%;
    width: 100%;
}

.home-imeah-navbar-links-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 1%;
}

.home-navbar-typography {
    font-size: 12px;
    color: #17536B;
    cursor: pointer;
    text-decoration: none;
}

.home-navbar-login{
    color: #17536B;
    cursor: pointer;
    font-size: 28px;

}

@media(max-width: 800px){
    .home-imeah-navbar-links-box {
        display: none;
    }

    .home-navbar-burgur-icon-btn{
        display:flex;
        margin-right: 0;
    }
    .home-imeah-navbar-logo {
       margin: 0 auto;
    }

}

@media(max-width: 450px){


    .home-imeah-navbar-logo {
       margin: 0 auto;
       width: 170px;
    }

    .home-navbar-burgur-icon-btn{
        width: 45px;
        height: 45px;
    }

}