.homeFourthPart-main-box {
    position: relative;
    height: 380px;
    max-height: none;
    background-color: white;
}

.homeFourthPart-avec-ehealth-box {
    display: flex;
    gap: 8px;
    margin-left: 12%;
}

.homeFourthPart-avec-typo {
    font-size: 40px;
    line-height: 52px;
    font-weight: 800;
    color: #17536B;
}

.homeFourthPart-ehealth-typo {
    font-size: 40px;
    line-height: 52px;
    font-weight: 800;
    color: #09C3CC;
}

.homeFourthPart-benifit-typo {
    position: relative;
    margin-left: 12%;
    font-size: 40px;
    line-height: 52px;
    font-weight: 800;
    color: #17536B;
    z-index: 1;
}

.homeFourthPart-image {
    position: absolute;
    width: 700px;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
}

@media(max-width: 600px){

    .homeFourthPart-avec-ehealth-box {
        justify-content: center;
        margin-left: 0;
    }

    .homeFourthPart-avec-typo {
        font-size: 30px;
    }

    .homeFourthPart-benifit-typo {
        text-align: center;
        margin-left: 0;
        font-size: 30px;
        line-height: 52px;
        font-weight: 800;
        color: #17536B;
        z-index: 1;
    }

    .homeFourthPart-ehealth-typo {
        font-size: 30px;
    }
    
    .homeFourthPart-benifit-typo {
        font-size: 30px;
    }
}

@media(max-width: 450px){

    .homeFourthPart-benifit-typo {
        font-size: 20px;
    }

    .homeFourthPart-avec-typo {
        font-size: 20px;
    }

    .homeFourthPart-ehealth-typo {
        font-size: 20px;
    }
    
    .homeFourthPart-benifit-typo {
        font-size: 20px;
    }
}