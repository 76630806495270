.fieldInSelect_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.fieldInSelect_dropdown_icon {
    width: 25px;
    height: 25px;
    color: #727179;
}