.template-page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #17536B;
  border-radius: 7px;
  padding: 7px;
  margin-bottom: 10px;
  width: 100%;
  height: 44px;
}

.template-page-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10%;
  width: 50%;
}

.template-page-title-icon {
  color: #ffffff;
  width: 16px;
  height: 16px;
}

.template-page-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #ffffff;
  white-space: nowrap;
}

.template-page-search-input{
  border: #ffffff 1px solid;
  border-radius: 5px;
  padding: 2px;
  color: #ffffff;
}

.template-page-title-icon-box {
  display: flex;
  gap: 5px;
  align-items: center;
}

.template-page-action-box {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.template-page-header-button {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: white;
  cursor: pointer;
}

.template-page-title-icon {
  font-size: 20px;
  cursor: pointer;
  color: #ffffff;
}

.template-page-filter-flag-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 3px;
}

.template-page-clear-filter-flag {
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  color: #17536B
}

.template-page-filter-flag-item {
  display: flex;
  align-items: center;
  gap: 3px;
  background-color: #17536B;
  padding: 5px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

@media(max-width: 500px) {
  .template-page-title-box {
      width: 70%;
  }

  .template-page-title {
    font-size: 12px;
  }
  
}