.mdf-list-table-titles {
    color: #1A2744;
    font-weight: 600;
    white-space: nowrap;
}

.mdf-list-table-filter-icon {
    color: #1A2744;
    cursor: pointer;
}

.mdf-list-responsive-card-details {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: 320px;
    border-radius: 12px;
    background-color:  #ecf0f6;
    padding: 12px;
}

.mdf-list-responsive-card-data {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.mdf-list-responsive-cards-box{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
}

.mdf-list-responsive-card-title-response-box {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.mdf-list-responsive-card-title {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #6C757D;
    white-space: nowrap;
}

.mdf-list-responsive-card-response {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #343A40;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mdf-list-responsive-card-status{
    display: flex;
    align-items: center;
    gap: 5px;
}

.mdf-list-files-fetched-Box{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
}

.mdf-list-files-fetched-nbr{
    border: 1px solid #CED4DA;
    border-radius: 8px;
    padding: 8px;
}

.mdf-list-files-fetched-label{
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}