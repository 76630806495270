.create-list-label {
    font-weight: bold;
    font-size: 18px;
    margin-left: 2%;
}

.create-list-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 2%;
}

.create-list-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.create-list-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}


.create-list-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.create-list-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.create-list-name-input {
    height: 30px;
    width: 35%;
}

.create-list-response-part {
    flex-basis: 70%;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin-bottom: 10px;
}

.close-icon {
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.create-list-values-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}

.add-values-input {
    width: 35%;
    margin-left: 31%;
}

.discard-create-box {
    display: flex;
    gap: 10px;
}

.select-list-values-cell {
    align-items: center;
}

.select_list_label_value_actions_box {
    display: flex;
    justify-content: space-between;
}

.select_list_value_actions_box {
    display: flex;
    gap: 10px;
    min-width: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    padding: 0 !important;

}

.select_list_value_action_edit {
    cursor: pointer;
    color: #17536B;
    width: 22px;
    height: 22px;
}

.select_list_value_action_delete {
    cursor: pointer;
    color: red;
    width: 22px;
    height: 22px;
}

.addValue_button {
    display: flex;
    justify-self: center;
    width: 40%;
    color: white;
    background-color: #17536B;
    border-radius: 7px;
}

.addValue_button:hover {
    color: white;
    background-color: #749fe8;
}

@media(max-width: 600px) {

    .create-list-content {
        gap: 20px;
    }

    .create-list-label {
        font-size: 13px;
    }

    .select-list-values-cell {
        display: flex;
        flex-wrap: wrap;
        width: 500px;
        margin-top: 22px;
    }

    .create-list-name-input {
        width: 65%;
    }

    .create-list-values-response {
        font-size: 12px;
    }

}