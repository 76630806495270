.recommandation_noRcom_Box {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50vh;
}

.recommandation_icon_box {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.recommandation_fields_container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 80%
}

.recommandation_validation_box {
    margin: 20px 0px;
    display: flex;
    justify-content: flex-end;
    width: 80%;
}

@media(max-width: 600px) {

    .recommandation_fields_container {
        width: 100%
    }

    .recommandation_validation_box {
        width: 100%;
    }

}