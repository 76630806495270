.editInstituion-actions-box {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 40px;
}

.editInstituion-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.editInstituion-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.editInstituion-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.editInstituion-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.editInstituion_switch_box{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
}

.editInstituion_switch_labels {
    font-size: 20px;
    font-weight: 400;
    color: #717171;
    width: 100%;
    text-align: center;
}

.editInstituion_switch_label_box{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.editInstituion-servise-legend {
    flex-basis: 70%;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin-bottom: 10px;
}

.editInstituion-servise-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}
