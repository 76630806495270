.edit-user-change-role-label{
    margin-top: 1%;
    color: gray;
    font-size: 13px;
}

.edit-user-labels{
    margin-top: 2%;
}

.filter-list-off{
    margin-top: 2%;
    margin-left: 2%;
    cursor: pointer;
}