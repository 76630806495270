.selectList-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.selectList-page-title-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10%;
    width: 70%;
}

.selectList-page-title-icon-box{
    display: flex;
    gap: 5px;
    align-items: center;
}

.selectList-page-filter-flag-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 3px;
}

.selectList-page-clear-filter-flag {
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #17536B
}

.selectList-page-filter-flag-item {
    display: flex;
    align-items: center;
    gap: 3px;
    background-color: #17536B;
    padding: 5px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.selectList-page-title-icon {
    color: #ffffff;
    width: 18px;
    height: 18px;
}

.selectList-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.selectList-page-search-input{
    border: #ffffff 1px solid;
    border-radius: 5px;
    padding: 2px;
    color: #ffffff;
}

.selectList-page-action-box {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.selectList-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 6px;
    color: white;
    cursor: pointer;
}

.selectList-table-titles {
    color: #1A2744;
    font-weight: 600;
    white-space: nowrap;
}


@media(max-width: 900px) {
    .selectList-button-title {
        display: none;
    }
}

@media(max-width: 500px) {

    .selectList-page-title {
        font-size: 12px;
    }
}