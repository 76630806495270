.homeThirdPart-main-box {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
}

.homeThirdPart-triangle-box {
    width: 0;
    height: 0;
    border-left: 400px solid transparent;
    border-right: 400px solid transparent;
    border-top: 200px solid #84E1E6;
}

.homeThirdPart-triangle-inside {
    margin: auto;
    position: absolute;
    justify-self: self-start;
    top: 10px;
    gap: 10px;
    width: 230px;
    height: 112px;
    text-align: center;
}

.homeThirdPart-triangle-first-typo {
    font-size: 35px;
    font-weight: 800;
    color: white;
}

.homeThirdPart-triangle-second-typo {
    font-size: 35px;
    font-weight: 800;
    color: #17536B;
}


@media(max-width: 1000px){
    .homeThirdPart-triangle-box {
        width: 0;
        height: 0;
        border-left: 300px solid transparent;
        border-right: 300px solid transparent;
        border-top: 150px solid #84E1E6;
    }

    .homeThirdPart-triangle-first-typo {
        font-size: 25px;
        font-weight: 800;
    }
    
    .homeThirdPart-triangle-second-typo {
        font-size: 25px;
        font-weight: 800;
    }
}

@media(max-width: 800px){
    .homeThirdPart-triangle-box {
        width: 0;
        height: 0;
        border-left: 200px solid transparent;
        border-right: 200px solid transparent;
        border-top: 120px solid #84E1E6;
    }

    .homeThirdPart-triangle-first-typo {
        font-size: 25px;
    }
    
    .homeThirdPart-triangle-second-typo {
        font-size: 25px;
    }
}

@media(max-width: 450px){
    .homeThirdPart-triangle-box {
        width: 0;
        height: 0;
        border-left: 150px solid transparent;
        border-right: 150px solid transparent;
        border-top: 80px solid #84E1E6;
    }

    .homeThirdPart-triangle-first-typo {
        font-size: 15px;
    }
    
    .homeThirdPart-triangle-second-typo {
        font-size: 15px;
    }
}