.oneInstitution-main-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.oneInstitution-main-page-header-box {
    display: flex;
    gap: 10px;
    margin-bottom: 2%;
}

.oneInstitution-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.oneInstitution-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.oneInstitution-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.oneInstitution-actions-box {
    display: flex;
    gap: 10px;
    align-items: center;
}

.oneInstitution-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 6px;
    color: white;
    cursor: pointer;
}

.oneInstitution-action-icon {
    font-size: 20px;
}