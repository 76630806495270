.fieldType_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.fieldType_header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fieldType_label_actions_container {
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    align-self: flex-end;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 17px;
}

.fieldType_edit_label_field_box {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.fieldType_label_container {
    display: flex;
    align-items: center;
    gap: 7px;
    flex-wrap: wrap;
}

.fieldType_edit_label_icon {
    width: 20px;
    height: 20px;
    color: #727179;
    cursor: pointer;
}

.fieldType_valid_label_icon {
    width: 20px;
    height: 20px;
    color: #3F952B;
    cursor: pointer;
}

.fieldType_cancel_label_icon {
    width: 20px;
    height: 20px;
    color: red;
    cursor: pointer;
}

/* document box  */

.fieldType-file-document-details-box {
    background-color: #ffffff;
    gap: 10px;
    padding: 12px;
    border-radius: 12px;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E8E8E8;
}

.fieldType-file-document-data-container {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.fieldType-file-icon-box {
    flex-basis: 10%;
    height: fit-content;
    color: #16536B;
    background-color: #E2EDFE;
    padding: 7px;
    border-radius: 50%;
    text-align: center;
}

.fieldType-file-doc-title {
    font-size: 12px;
}

.fieldType-upload-box {
    border: 1px dashed #16536B;
    width: 100%;
    height: 168px;
    background-color: #F1F9FC;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
    border-radius: 12px;
}

@media(max-width: 600px) {

    .fieldType_header_container {
        align-items: flex-start;
    }

    .fieldType_edit_label_field_box {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

}