.emailSent_main_box {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 60%;
    border: 1px solid #C4C4C4;
    border-radius: 12px;
    padding: 40px;
}

.emailSent_image {
    width: 200px;
    margin: 0 auto;
}

.emailSent_title_typo_box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;

}

.emailSent_title {
    color: #333;
    font-family: "Public Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
}

.emailSent__tryAgain_box {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.emailSent__typo {
    color: #333;
    text-align: center;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: center;
}

.emailSent__tryAgain {
    color: var(--components-button-link-deault, #727179);
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    cursor: pointer;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
}

@media(max-width: 1000px) {
    .emailSent_main_box {
        width: 90%;
    }
}

@media(max-width: 600px) {
    .emailSent_main_box {
        padding-left: 20px;
        padding-right: 20px;
    }

    .emailSent_title{
        font-size: 24px;
        line-height: 32px;
    }
}