.homeSecondPart-main-box {
    position: relative;
    background-color: #84E1E6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    min-height: 450px;
    max-height: none;
}

.homeSecondPart-card-box {
    align-self: flex-start;
    margin-top: 7%;
    border-radius: 10px;
    padding: 10px;
    width: 300px;
    min-height: 178px;
    max-height: none;
    background-color: #17536B;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
    z-index: 1;
    animation-duration: 5s;
}

.homeSecondPart-card-list-item {
    display: flex;
    gap: 1px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.homeSecondPart-card-list {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
}

.homeSecondPart-card-typo {
    width: 280px;
    height: 35px;
    line-height: 28px;
    font-size: 20px;
    color: white;
    text-align: center;
    vertical-align: top;
    font-weight: 800;
}

.homeSecondPart-card-icon-eye {
    width: 55px;
    height: 38px;
    margin: 0 auto;
}

.homeSecondPart-card-icon-home {
    width: 48px;
    height: 50px;
    margin: 0 auto;
}

.homeSecondPart-card-icon-point {
    width: 50px;
    height: 50px;
    margin: 0 auto;
}

.homeSecondPart-card-icon-arrow {
    width: 20px;
    height: 10px;
    margin: 0 auto;
    cursor: pointer;
}

.homeSecondPart-card-icon-record {
    color: white;
    width: 10px;
}

.homeSecondPart-card-hidden-typo {
    color: white;
    text-align: start;
}

.homeSecondPart-card-loop-img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 310px;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
}