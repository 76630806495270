.otherField_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.otherField_header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.otherField_label_actions_container {
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    align-self: flex-end;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 17px;
}

.otherField_edit_label_field_box {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.otherField_label_container {
    display: flex;
    align-items: center;
    gap: 7px;
    flex-wrap: wrap;
}

.otherField_edit_label_icon {
    width: 20px;
    height: 20px;
    color: #727179;
    cursor: pointer;
}

.otherField_valid_label_icon {
    width: 20px;
    height: 20px;
    color: #3F952B;
    cursor: pointer;
}

.otherField_cancel_label_icon {
    width: 20px;
    height: 20px;
    color: red;
    cursor: pointer;
}

@media(max-width: 600px) {

    .otherField_header_container {
        align-items: flex-start;
    }

    .otherField_edit_label_field_box {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

}