.main {
    background-color: #1C1E20;
    display: flex;
    height: 100vh;
}

.main__left_part_width {
    flex-direction: column;
    display: flex;
    flex: 0.8;
}

#nameTag {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    border-radius: 5px;
}

video {
    width: 100%;
    height: 100%;
}

.main__left_full_width {
    flex-direction: column;
    display: flex;
    flex: 1;
}

#video-grid {
    display: flex;
    height: 89vh;
    margin-top: 1vh;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3%;
    border-radius: 5px;
}

video {
    object-fit: cover;
    border-radius: inherit;
}

.main__controls {
    height: 10vh;
    background-color: #1C1E20;
    justify-content: center;
    display: flex;
    padding: 10px;
    gap: 15px;
}

.main__right {
    flex: 0.2;
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: #242324;
    border-left: 1px solid #3D3D42;
    border-radius: 5px 5px 0px 0px;
}


.main__chat_window {
    flex-grow: 1;
    overflow-y: auto;
}

.messages {
    color: white;
    font-size: 10px;
}

.messageSender {
    background-color: rgba(135, 97, 124, 0.5);
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 8px;

}

.main__message_container {
    padding: 10px 10px;
}

.main__message_container input {
    flex-grow: 1;
    background-color: transparent;
    border: none;
    color: #F5F5F5;
}

.offIcon {
    background: rgb(234, 67, 53);
    color: white;
    border-radius: 100px;
    width: 3.5rem;
    height: 2.5rem;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.offIcon:hover {
    transform: scale(1.1);
}

.onIcon {
    background: rgb(60, 64, 67);
    color: white;
    border-radius: 100px;
    width: 3.5rem;
    height: 2.5rem;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.onIcon:hover {
    transform: scale(1.1);
}

@media(max-width: 1200px) {
    .main__left_part_width {
        flex: 0.6;
    }

    .main__right {
        flex: 0.4;
    }
}

@media(max-width: 450px) {
    .main__left_part_width {
        flex: 0.5;
    }

    .main__right {
        flex: 0.5;
    }
}