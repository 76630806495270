.homeFirstPart-main-box {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: white;
}

.homeFirstPart-first-typography-box {
    display: flex;
    margin-left: 17%;
    gap: 8px;
    text-align: start;
    width: 70%;
    flex-wrap: wrap;
    font-size: 46px;
}

.homeFirstPart-first-typography-text-one {
    font-size: 28px;
    font-weight: 800;
    line-height: 35px;
    color: #09C3CC;
    display: inline-block;
    margin-right: 5px;
    font-family: sans-serif;
}

.homeFirstPart-first-typography-text-two {
    font-size: 100%;
    font-weight: 800;
    line-height: 56px;
    color: #09C3CC;
    display: inline-block;
    font-family: sans-serif;
}

.home-imeah-firstPage-typo-img-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.home-imeah-firstPage-logo-typo-box {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-left: 17%;
    flex-basis: 32%;
}

.home-imeah-firstPage-logo {
    width: 100%;
}

.home-imeah-firstPage-typo-part {
    color: #17536B;
    font-size: 24px;
    width: 100%;
}

.home-imeah-firstPage-firstImg {
    margin-top: 5%;
    width: 30%;
    flex-basis: 50%;
}

@media(max-width: 1000px){
    .homeFirstPart-first-typography-box {
        margin-left: 5%;
    }

    .home-imeah-firstPage-logo-typo-box {
        margin-left: 5%;
        flex-basis: 30%;
    }

    .home-imeah-firstPage-firstImg {
        margin-top: 5%;
        width: 20%;
        flex-basis: 60%;
    }

    .homeFirstPart-first-typography-box {
        justify-content: center;
        text-align: center;
        width: 90%;
    }
}

@media(max-width: 850px) {

    .homeFirstPart-main-box{
        margin-top: 100px;
        gap: 50px;
    }

    .homeFirstPart-first-typography-box {
        margin-left: 0%;
        text-align: center;
        margin: auto;
        width: 70%;
    }

    .home-imeah-firstPage-logo-typo-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0%;
        gap: 15px;
    }
    
    

    .homeFirstPart-first-typography-text-one {
        font-size: 25px;
        text-align: center;
    }
    .homeFirstPart-first-typography-text-two {
        font-size: 80%;
        text-align: center;
    }
    
    .home-imeah-firstPage-typo-img-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
    
    }

    .home-imeah-firstPage-logo {
        width: 50%;
    }

    .home-imeah-firstPage-firstImg {
        width: 80%;
    }

    .home-imeah-firstPage-typo-part {
        color: #17536B;
        font-size: 24px;
        width: 90%;
        text-align: center;
    }
}

@media(max-width: 600px){
    .homeFirstPart-first-typography-text-one {
        font-size: 20px;
        height: 40px;
        line-height: 25px;
    }
    .homeFirstPart-first-typography-text-two {
        font-size: 60%;
        height: 40px;
    }
}

@media(max-width: 450px){
    .homeFirstPart-first-typography-text-one {
        font-size: 20px;
    }
    .homeFirstPart-first-typography-text-two {
        font-size: 50%;
    }

    .home-imeah-firstPage-typo-part {
        color: #17536B;
        font-size: 18px;
    }
}

@media(max-width: 355px){
    .homeFirstPart-first-typography-text-one {
        font-size: 20px;
    }
    .homeFirstPart-first-typography-text-two {
        font-size: 45%;
    }
}