@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100dvh;
}

.login-Box {
  width: 65%;
  margin: 0 auto;
  border: 1px solid #C4C4C4;
  border-radius: 12px;
  padding: 40px;
}

.loginForm_image_box {
  display: flex;
  align-items: center;
  gap: 40px;
}

.loginForm_box {
  flex-basis: 65%;
}

.image_box {
  flex-basis: 35%;
}

.login_title {
  color: #333;
  font-family: "Public Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 10px;
}

.login-visit-web {
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  margin-bottom: 25px;
}

.login_newHere {
  color: #333;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.login_visitWeb {
  color: var(--components-button-link-deault, #727179);
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.login-submitForm {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 25px;
}

.secondaryActionBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 15px;
}

.registerLinkBox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.registerLinkLabel {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #17536B;
  opacity: 70%;
  font-family: 'Roboto', sans-serif;
}

.registerLink {
  color: #09c3cc;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.login-forgotPass {
  opacity: 70%;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #17536B;
  cursor: pointer;
}


.login-loginButton {
  display: flex;
  align-self: flex-end;
  width: 186px;
  padding: 12px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  color: white;
  background: var(--components-button-filled-background, #16536B);
}

.login-loginButton:hover {
  opacity: 0.8;
  background: var(--components-button-filled-background, #16536B);

}

@media(max-width: 1000px) {
  .login-Box {
    width: 90%;
  }
}

@media(max-width: 650px) {
  .login-Box {
    width: 90%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .image_box {
    display: none;
  }

  .loginForm_box {
    flex-basis: 100%;
  }

  .registerLinkLabel {
    font-size: 12px;
    line-height: 12px;
  }

  .registerLink {
    font-size: 12px;
    line-height: 12px;
  }

  .login-forgotPass {
    font-size: 12px;
    line-height: 12px;
  }

}