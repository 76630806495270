.create-template-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.create-template-pages-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.create-temp-target-doctor {
    margin-top: 20px;
    bottom: 10px;
    border-radius: 25px;
    background-color: rgba(145, 158, 171, 0.12);
}

.create-temp-formControlLabel-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 20px;
}

.create-temp-formControlLabel {
    padding: 10px;
    border-radius: 10px;
    color: white;
    background-color: #17536B;
}

.create-temp-doctorAvailable-typo {
    text-align: center;
    font-size: 25px;
    border-bottom: 2px solid black;
}

.createTemp-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.createTemp-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.createTemp-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.createTemp-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.createTemp-actions-box {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 40px;
}

.createTemp-requiredNote{
    color: gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
}

@media(max-width: 500px) {
    .create-template-name-desc-box {
        flex-direction: column;
    }
}