.create-mdf-actions-box {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.createMdf-page {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.createMdf-mainTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    color: #181D25;
}

.createMdf-secondTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #828282;
}

.create-MDF-header {
    width: 100%;
    height: 38px;
    border-radius: 7px;
    background-color: #17536B;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}

.back-btn-import {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
}

.createMdf-mainTitle-createMdf-secondTitle {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 75%;
}

.createMdf-importBtn {
    position: absolute;
    right: 30px;
    color: white;
    background-color: #17536B;
    border-radius: 10px;
}

.createMdf-actions-buttons {
    background-color: #17536B;
    color: white;
    width: 95px;
    cursor: pointer;
}

.createMdf-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 36px;
    padding-left: 7px;
    cursor: pointer;
}

.createMdf-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 6px;
    color: white;
    cursor: pointer;
}

.createMdf-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 20px;
    width: 100%;
    height: 44px;
}


.createMdf-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.createMdf-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.createMdf-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.createMdf-page-title-icon {
    font-size: 20px;
    color: #ffffff;
}

@media(max-width: 900px) {
    .createMdf-button-title {
        display: none;
    }
}

@media (max-width: 500px) {
    .createMdf-mainTitle {
        font-size: 16px;
    }

    .createMdf-secondTitle {
        font-size: 12px;
    }

    .createMdf-importBtn {
        position: absolute;
        right: 0px;
        font-size: 10px;
    }
}