.onefile_loading {
    position: absolute;
    top: 55%;
    margin-left: 40%;
}

.onefile-top-bar {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    justify-content: space-between;
}

.onefile-back-btn {
    color: #727179;
    border-radius: 7px;
    width: 34px;
    height: 34px;
    cursor: pointer;
}

.onefile-tabPanel {
    padding: 0px;
    margin: 0px;
}

.onefile-action-button-box {
    display: flex;
    gap: 5px;
    background-color: #17536B;
    align-items: center;
    height: 35px;
    justify-content: flex-end;
    padding: 7px;
    border-radius: 7px;
    height: 44px;
    width: 100%;
}

.onefile-btn-container {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 3px;
    color: #ffffff;
    cursor: pointer;
}

.onfile-headerBtn-icon {
    font-size: 20px;
}

.onefile-btn-title {
    font-size: 12px;
}

@media(max-width: 600px) {

    .onefile-top-bar {
        gap: 15px;
    }

    .onfile-headerBtn-icon {
        font-size: 16px;
    }

    .onefile-back-btn {
        width: 20px;
        height: 20px;
    }

}