.mdf-page-style {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.mdf-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.mdf-page-title-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10%;
    width: 50%;
}

.mdf-page-title-icon {
    color: #16536B;
    width: 16px;
    height: 16px;
}

.mdf-page-title {
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    color: #000000;
    white-space: nowrap;
}

.mdf-page-search-input {
    border: #ffffff 1px solid;
    border-radius: 5px;
    color: #ffffff;
}

.mdf-page-title-icon-box {
    display: flex;
    gap: 5px;
    align-items: center;
}

.mdf-page-action-search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mdf-page-action-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
}

.mdf-page-search-input{
    height: 40px;
    width: 50%;
}

.mdf-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: #16536B;
    cursor: pointer;
    border: 1px solid #16536B;
    border-radius: 8px;
    padding: 8px;
}

.mdf-page-filter-flag-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 3px;
}

.mdf-page-clear-filter-flag {
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #17536B
}

.mdf-page-filter-flag-item {
    display: flex;
    align-items: center;
    gap: 3px;
    background-color: #17536B;
    padding: 5px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

@media(max-width: 900px) {
    .mdf-icon-button {
        display: none;
    }

    .mdf-page-style {
        padding: 10px;
    }
}

@media(max-width: 600px) {

    .mdf-page-style {
        gap: 15px;
    }

    .mdf-page-title-box {
        width: 70%;
    }

    .mdf-page-title {
        font-size: 24px;
        line-height: 32px;
    }

    .mdf-page-action-search-box{
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .mdf-page-search-input{
        width: 100%;
    }
}