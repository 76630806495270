.permissions-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.permissions-page-title-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10%;
    width: 70%;
}

.permissions-page-title-icon-box {
    display: flex;
    gap: 5px;
}

.permissions-page-title-icon {
    color: #ffffff;
    width: 18px;
    height: 18px;
}

.permissions-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.permissions-page-search-input {
    border: #ffffff 1px solid;
    border-radius: 5px;
    padding: 2px;
    color: #ffffff;
}

.permissions-page-action-box {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.permissions-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 6px;
    color: white;
    cursor: pointer;
}

@media(max-width: 900px) {
    .permissions-button-title {
        display: none;
    }
}


.permissions-list-responsive-card-details {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    color: white;
    border-radius: 7px;
    background-color: #17536B;
    padding: 5px;
}

.permissions-list-responsive-card-data {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.permissions-card-actions {
    display: flex;
    justify-content: space-around;
}

.permissions-actions-btn {
    background-color: #17536B;
    border-radius: 50%;
    font-size: 33px;
    padding: 5px;
    color: white;
    cursor: pointer;
}

@media(max-width: 500px) {

    .permissions-page-title {
        font-size: 12px;
    }
}