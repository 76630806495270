.homeNinePart-main-box {
    margin-top: 7%;
    display: flex;
    align-items: flex-end;
    margin-left: 3.5%;
    width: 100%;
}

.homeNinePart-image {
    width: 80%;
}

.homeNinePart-typo-box {
    margin-left: 9%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 80%;
}

.homeNinePart-typo-aussi {
    color: #17536B;
    font-size: 40px;
    font-weight: 800;
    line-height: 52px;
}

.homeNinePart-typo-dr-junior {
    margin-top: 7%;
    color: #17536B;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    width: 80%;
    text-align: start;
    align-self: flex-start;
}

.homeNinePart-typo-ehealth {
    font-weight: 800;
    color: #09C3CC;
    font-size: 40px;
}

@media(max-width: 1300px) {

    .homeNinePart-main-box {
        flex-direction: column;
        margin-left: 0;
        width: 100%;
    }

    .homeNinePart-typo-ehealth {
        font-size: 30px;
    }

    .homeNinePart-typo-aussi {
        font-size: 30px;
    }

    .homeNinePart-typo-dr-junior {
        width: 90%;
    }
}

@media(max-width: 600px) {
    .homeNinePart-main-box {
        align-items: center;
        gap: 30px;
    }

    .homeNinePart-typo-box {
        width: 90%;
        margin-left: 0;
        align-self: center;
    }

    .homeNinePart-image {
        width: 90%;
    }

    .homeNinePart-typo-ehealth {
        text-align: center;
    }

    .homeNinePart-typo-aussi {
        text-align: center;
    }

    .homeNinePart-typo-dr-junior {
        align-self: center;
        text-align: center;
    }
}

@media(max-width: 450px) {

    .homeNinePart-typo-aussi {
       font-size: 20px;
    }
}