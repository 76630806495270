.homeSixPart-main-box {
    position: relative;
    min-height: 400px;
    background-color: #056266;
    max-width: 100%;
}

.homeSixPart-image {
    position: absolute;
    width: 350px;
    bottom: 80px;
    left: 20px;
}

.homeSixPart-typo-box {
    max-width: 90%;
    position: absolute;
    left: 30%;
    top: 15%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.homeSixPart-typo-ehealth-box {
    display: flex;
    gap: 10px;
}

.homeSixPart-typo-ehealth {
    color: #09C3CC;
    font-size: 40px;
    font-weight: 800;
    line-height: 52px;
}

.homeSixPart-typo-aussi {
    color: white;
    font-size: 40px;
    font-weight: 800;
    line-height: 52px;
}

.homeSixPart-typo-dr-junior {
    margin-top: 5%;
    font-size: 18px;
    line-height: 25px;
    color: white;
}

@media(max-width: 1250px) {
    .homeSixPart-image {
        width: 300px;
        left: 5px;
    }

    .homeSixPart-typo-ehealth {
        font-size: 30px;
    }

    .homeSixPart-typo-aussi {
        font-size: 30px;
    }

    .homeSixPart-typo-dr-junior {
        font-size: 14px;
    }

    .homeSixPart-typo-box {
        width: 60%;
        left: 40%
    }

}

@media(max-width: 800px) {
    .homeSixPart-main-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-height: none;
    }

    .homeSixPart-image {
        position: relative;
        bottom: 50px;
    }

    .homeSixPart-typo-box {
        width: 90%;
        position: relative;
        left: 0;
        bottom: 15px;
        align-items: center;
        justify-content: center;
    }

    .homeSixPart-typo-aussi{
        text-align: center;
    }

    .homeSixPart-typo-dr-junior{
        text-align: center;
    }

}

@media(max-width: 450px) {
    .homeSixPart-typo-ehealth {
        font-size: 22px;
    }

    .homeSixPart-typo-aussi {
        font-size: 22px;
    }

    .homeSixPart-typo-dr-junior {
        font-size: 12px;
    }
}