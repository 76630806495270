.addSkills-servise-legend {
    flex-basis: 70%;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin-bottom: 10px;
}

.addSkills-servise-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}

.addSkills-actions-box {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 40px;
}
