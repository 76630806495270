.homeTenPart-main-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 600px;
    background-color: #056266;
    width: 100%;
}

.homeTenPart-logo-arrow-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.homeTenPart-typo-btn-box {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.homeTenPart-typo-part-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.homeTenPart-typo-telecharger {
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
    color: white;
}

.homeTenPart-typo-ehealth {
    font-weight: 900;
    font-size: 30px;
    line-height: 39px;
    color: #84E1E6;
}

.homeTenPart-btn-part {
    width: 226px;
    height: 54px;
    border-radius: 16px;
    padding: 5px, 20px, 5px, 20px;
    background-color: orange;
    color: white;
}

.homeTenPart-btn-part:hover {
    background-color: #84E1E6;
}

.homeTenPart-mobiles-img{
    width: 40%;
}

.homeTenPart-logo-typo-main-box{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

@media(max-width: 1250px){
    .homeTenPart-main-box {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .homeTenPart-logo-typo-main-box{
        justify-content: center;
        margin-top: 5%;
    }

    .homeTenPart-mobiles-img{
        width: 500px;
    }
}

@media(max-width: 850px){
    .homeTenPart-typo-telecharger {
        font-size: 20px;
    }
    .homeTenPart-typo-ehealth {
        font-size: 25px;
    }
}

@media(max-width: 650px){
    .homeTenPart-mobiles-img{
        width: 400px;
    }
    .homeTenPart-logo-icon{
        width: 120px;
    }
    .homeTenPart-main-box {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;
    }
    .homeTenPart-typo-ehealth {
        text-align: center;
    }
    .homeTenPart-typo-telecharger {
        text-align: center;
    }
}

@media(max-width: 550px){
    .homeTenPart-logo-arrow-box{
        display: none;
    }
    .homeTenPart-typo-btn-box{
        align-items: center;
        justify-content: center;
    }
    .homeTenPart-mobiles-img{
        width: 300px;
    }
    .homeTenPart-main-box {
        min-height: 450px;
    }
    
}