/* Variables for reuse */
:root {
    --primary-color: #16536B;
    --secondary-color: #727179;
    --white-color: white;
}

/* Global Styles */
body {
    font-family: 'Public Sans', sans-serif;
}

.css-16fal0e {
    padding-bottom: 0 !important;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
    width: 9px;
}

::-webkit-scrollbar-track {
    background: #F3F3F3;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #79747E;
    border-radius: 10px;
}

/* custom textField styles */
.customTextField .MuiOutlinedInput-root fieldset {
    border-color: var(--secondary-color);
    border-radius: 12px;
}

.customTextField .MuiOutlinedInput-root:not(.Mui-disabled):hover fieldset {
    border-color: var(--primary-color);
}

.customTextField .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--primary-color);
}

.customTextField .MuiFormLabel-root {
    color: var(--primary-color);
}

.customTextField .MuiFormLabel-root.Mui-focused {
    color: var(--primary-color);
}

.customTextField .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-input {
    color: black;
    -webkit-text-fill-color: black;
}

/* custom button styles */
.buttonFilled {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white-color);
    border-radius: 4px;
    width: fit-content;
    height: auto;
}

.buttonFilled:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: 1px solid var(--primary-color);
    border-color: var(--primary-color);
}

.buttonOutlined {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    width: fit-content;
    height: auto;
}

.buttonOutlined:hover {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

/* custom title styles */
.imeahTitle {
    font-family: 'Public Sans', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    text-transform: lowercase;
    color: #000000;
}

.imeahTitle::first-letter {
    text-transform: uppercase;
}

/* custom subtitle styles */
.imeahSubTitle {
    text-transform: lowercase;
    font-family: 'Public Sans', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #000000;
}

.imeahSubTitle::first-letter {
    text-transform: uppercase;
}

/* custom propre name styles */
.imeah_propre_name {
    text-transform: lowercase;
}

.imeah_propre_name::first-letter {
    text-transform: uppercase;
}

.imeah_persone_name {
    text-transform: capitalize;
}

/* custom caption styles */
.imeahCaption {
    text-transform: lowercase;
    font-size: 12px;
    font-family: 'Public Sans', sans-serif;
    font-weight: 500;
    color: #757575;
}

.imeahCaption::first-letter {
    text-transform: uppercase;
}

/* custom radio styles */
.imeahRadio .MuiSvgIcon-root {
    color: var(--primary-color);
}

.imeahRadio.Mui-checked .MuiSvgIcon-root {
    color: var(--primary-color);
}

/* custom switch styles */
.imeahSwitch .MuiSwitch-switchBase {
    color: var(--primary-color);
}

.imeahSwitch .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: var(--primary-color);
}

/* custom checkBox styles */
.imeahCheckbox .MuiSvgIcon-root {
    color: var(--primary-color);
}

.imeahCheckbox.Mui-checked .MuiSvgIcon-root {
    color: var(--primary-color);
}

/* custom ships styles */

.imeah_ships_box {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    width: 100%;
    max-width: max-content;
    border-radius: 12px;
    color: #4A4459;
    background-color: #D1F2FF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.imeah_ships_button {
    width: 20px;
    height: 20px;
}

.imeah_ships_icon {
    font-size: 20px;
    color: #4A4459;
}

/* custom filterIcon styles */
.imeah_filter_icon {
    font-size: 16px;
    cursor: pointer;
    z-index: 1;
}

/* custom dialog styles */

.MuiDialog-paper {
    background-color: #F1F9FC !important;
    border-radius: 12px;
}

.imeah_dialog_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.imeah_dialog_icon {
    width: 48px;
    height: 48px;
    color: #727179;
}

.imeah_dialog_title {
    text-align: center;
    font-family: "Public Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.imeah_dialog_subtitle {
    color: #49454F;
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

@media (max-width: 600px) {

    .imeahTitle {
        font-size: 24px;
    }

    .imeahSubTitle {
        font-size: 14px;
    }

    .imeahCaption {
        font-size: 10px;
    }
}

/* custom radio styles */
.imeahRadio .MuiSvgIcon-root {
    color: var(--primary-color);
}

.imeahRadio.Mui-checked .MuiSvgIcon-root {
    color: var(--primary-color);
}

.MuiDialog-paper {
    background-color: #F1F9FC !important;
}

/* imeahFilterMenu scroll css */
.imeahFilterMenu-scrollbar::-webkit-scrollbar {
    height: 5px;
}

.imeahFilterMenu-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: thin;
}

/* table css */
.table {
    width: 100%;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
}

.react_window_list::-webkit-scrollbar {
    /* Set width for vertical scrollbar */
    width: 9px;
    /* Set width for horizontal scrollbar */
    height: 9px;
}

.tableHead {
    width: 'fit-content';
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    overflow: auto;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
}

.tableHead::-webkit-scrollbar {
    /* Safari and Chrome */
    display: none;
}

/* custom tableCell styles */
.imeah_tableHeaderCell_box {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 45px;
    background-color: white;
    padding: 10px 20px;
    border-radius: 12px;
    border: 1px solid var(--Neutral-400, #CED4DA);
    font-family: "Public Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: var(--components-table-text_secondary, #727179);
}

.tableRow {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    /* padding:  24px; */
    border-radius: 12px;
    padding: 6px;
    border: 1px solid var(--Neutral-400, #CED4DA);

    /* align-self: stretch; */
    background-color: white;
}

.tableBodyCell {
    display: flex;
    align-items: center;
    padding: 6px;
    height: 50px;
    padding-left: 15px;
}

.tableBodyTypography {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Public Sans";
    font-size: 15px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--components-table-text, #535353);
}

/* before 26/12/2024 */

.pages-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
}

.circleLoading {
    position: absolute;
    top: 55%;
    margin-left: 40%;
}

.button {
    color: #34697E;
    background-color: #84E1E6;
    border-radius: 40px;
}

.button:hover {
    color: #34697E;
    background-color: #84E1E6;
}


.filter-input {
    margin: 1%;
    width: 150px;
}

.table-cell {
    padding-left: 1.5%;
    height: 50px;
    padding: 0px;
    padding-left: 15px;
}

.table-stack {
    display: flex;
    flex-direction: row;
    margin: 2%;
}

.error-message {
    text-align: center;
    color: red;
}

.delete-icon {
    cursor: pointer;
    color: red;
}

.account-icon {
    cursor: pointer;
    color: #2E3F62;
    font-size: 28px;
}

.chip-box {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5;
}

.accept-icon {
    cursor: pointer;
    color: green;
}

.form-control {
    margin-top: 10px;
}

.main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
}

.new-delete-icon {
    color: white;
    cursor: pointer;
}


.actions-btn {
    background-color: #17536B;
    border-radius: 50%;
    font-size: 33px;
    padding: 5px;
}

.back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 36px;
    padding-left: 7px;
    cursor: pointer;
}

.page-title {
    width: fit-content;
    font-size: 28px;
    font-weight: 400;
    line-height: 45px;
    color: #17536B;
}

.search-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 80%;
}

.search-input {
    width: 350px;
    border-radius: 5px;
    background-color: #F2F3F2;
}

.new-button {
    width: fit-content;
    color: white;
    background-color: #17536B;
    border-radius: 7px;
}

.new-button:hover {
    color: white;
    background-color: #749fe8;
}

.plus-icon {
    font-size: 20px;
}

.table-row {
    border-bottom: 2px solid #17536B;
}

.table-titles {
    color: #1A2744;
    /* width: fit-content; */
}

.select-input {
    width: 160px;
}

.filtration-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    margin: auto;
    width: 95%;
    height: 75px;
    border-radius: 100px;
    background-color: #F2F3F2;
    margin-top: 5px;
}

.scrollable {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 25px;
}

.scrollable>*:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 30%;
    right: -20px;
    width: 1px;
    height: 25px;
    background-color: grey;
}

.form-control-box {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 25px;
    margin-top: 6px;
}

.form-control-box>*:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 30%;
    right: -20px;
    width: 1px;
    height: 25px;
    background-color: grey;
}

.filterListOff-btn {
    padding: 6px;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #17536B;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

}

.chip {
    border-radius: 5px;
    background-color: #C4E9EB;
    padding: 20px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.card-responsive-main-box {
    display: none;
}

.card-details {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 200px;
    font-size: 16px;
    color: white;
    border-radius: 7px;
    background-color: #17536B;
    padding: 5px;
}

/* .temp-card-label {
    text-align: center;
    background-color: #84E1E6;
    color: #17536B;
    border-radius: 7px;
} */

.card-resp-part {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.card-actions {
    margin-top: 10px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-around;
}

.alert-complementary {
    position: fixed;
    bottom: 5%;
    left: 20%;
}

.back-title-search-header {
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin: 20px;
}

.dayBox_mainBox {
    display: flex;
}

.day_check_box {
    display: flex;
}

.day_typo {
    background-color: #34697E;
    color: white;
    padding: 5px;
    border-radius: 5px;
    width: 130px;
    font-size: 18px;
    font-weight: bold;
}

.flash-red {
    color: red;
}

@media(max-width: 900px) {
    .new-button-title {
        display: none;
    }
}

@media(max-width: 850px) {
    .search-input {
        width: 250px;
    }
}

@media(max-width: 500px) {

    .pages-header {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
    }

    .page-title {
        width: 100%;
    }

    .search-title-box {
        flex-direction: column;
    }

    .page-title {
        line-height: normal;
        text-align: center;
        font-size: 25px;
    }



    .plus-icon {
        font-size: 16px;
    }

    .card-responsive-main-box {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }

    .table-big-screen {
        display: none;
    }

    .back-title-search-header {
        flex-direction: column;
        align-items: center;
        gap: 25px;
    }

    .day_typo {
        width: 80px;
        font-size: 13px;
    }
}

/* from badih design  */

.new-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
}

.new-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}


.new-page-title-icon {
    color: #ffffff;
    width: 16px;
    height: 16px;
}

.new-page-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.new-page-action-box {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.new-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 6px;
    color: white;
    cursor: pointer;
}

.selected-tab {
    color: #17536B !important;
    font-weight: 600;
}

.normal-tab {
    color: #A8ADB8;
    font-weight: 600;
}

.new-table-titles {
    color: #1A2744;
    font-weight: 600;
}

.filter-table-cell {
    display: flex;
    align-items: center;
}

.table-filter-icon {
    color: #1A2744;
    cursor: pointer;
}

.new-table-cell {
    height: 50px;
    padding: 5px;
    padding-left: 15px;
}

.circle-add-icon {
    font-size: 35px;
    color: #34697E;
    cursor: pointer;
}

.main-page-threeDot-icon {
    color: #ffffff;
    width: 16px;
    height: 16px;
}

.main-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: white;
    cursor: pointer;
}

.main-page-filter-flag-item {
    display: flex;
    align-items: center;
    gap: 3px;
    background-color: #17536B;
    padding: 5px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.filter-flag-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 3px;
}

.clear-filter-flag {
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #17536B
}

.responsive-card-details {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    color: white;
    border-radius: 7px;
    background-color: #17536B;
    padding: 5px;
}