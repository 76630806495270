.homeEightPart-main-box {
    position: relative;
    padding: 3%;
    margin-top: 7%;
    min-height: 900px;
    max-height: none;
    background: linear-gradient(to bottom, #09C3CC, #17536B);
}

.homeEightPart-main-typo {
    font-weight: 800;
    font-size: 40px;
    line-height: 52px;
    color: white;
    position: inherit;
    margin-bottom: 3%;
    left: 17%;
}

.homeEightPart-cards-box {
    position: relative;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}

.homeEightPart-card-style {
    width: 440px;
    height: 600px;
    border-radius: 10px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.55);
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.homeEightPart-card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
    width: 80%;
    height: 30px;
    border-radius: 20px;
    background-color: #09C3CC;
    font-size: 24px;
    font-weight: 800;
    line-height: 22px;
    color: white;
}

.homeEightPart-card-title-jane {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
    width: 80%;
    height: 30px;
    border-radius: 20px;
    background-color: #17536B;
    font-size: 24px;
    font-weight: 800;
    line-height: 22px;
    color: white;
}

.homeEightPart-card-Mask {
    width: 150px;
}

.homeEightPart-card-Mask-clinic{
    width: 170px;
    height: 150px;
    border-radius: 50%;
}

.homeEightPart-card-speciality-typo {
    font-size: 24px;
    font-weight: 800;
    line-height: 22px;
    text-align: center;
    color: #374649;
}

.homeEightPart-card-desc-typo {
    width: 90%;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: #374649;
}

.homeEightPart-card-hidden-typo {
    font-size: 18px;
    font-weight: 400;
    font-family: sans-serif;
    line-height: 24px;
    color: #374649;
}

.homeEightPart-card-icon-record {
    width: 11px;
    color: #374649;
}

.homeEightPart-card-list {
    display: flex;
    width: 100%;
    gap: 10px;
}

.homeEightPart-card-list-item {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.homeEightPart-card-list-box {
    display: flex;
    justify-content: center;
}

@media(max-width: 950px) {

    .homeEightPart-main-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .homeEightPart-main-typo {
        position: inherit;
        left: 0%;
        margin-top: 9%;
    }

    .homeEightPart-cards-box {
        position: relative;
        gap: 20px;
        margin-bottom: 40px;
    }
}

@media(max-width: 450px) {
    .homeEightPart-main-typo {
        font-size: 30px;
    }

    .homeEightPart-card-style {
        width: 90%;
        height: 610px;
    }

    .homeEightPart-card-title {
        font-size: 18px;
    }

    .homeEightPart-card-hidden-typo {
        font-size: 14px;
    }
}

@media(max-width: 350px) {
    .homeEightPart-main-typo {
        font-size: 25px;
    }

    .homeEightPart-card-style {
        width: 90%;
        height: 610px;
    }
}