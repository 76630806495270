.contactUs-main-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #17536B;
    height: 900px;
    width: 100%;
}

.contactUs-card {
    width: 70%;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contactUs-title {
    color: #295380;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    padding-bottom: 25px;
}

.contactUs-input-Container {
    display: flex;
}

.contactUs-label {
    color: #17536B;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}

.contactUs-inputs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
}

.contactUs-input-container {
    width: calc(50% - 10px);
}

.contactUs-input {
    font-weight: 400;
    font-size: 16px;
    border-radius: 5px;
}

.contactUs-Button {
    font-weight: 800;
    size: 20px;
    line-height: 24px;
    background-color: #FF993A;
    color: white;
    border-radius: 16px;
    padding: 0px 20px;
    width: 159px;
    height: 44px;
    align-self: center;
}

.contactUs-Button:hover {
    background-color: #17536B;
}

@media(max-width: 900px) {
    .contactUs-input-container {
        width: 100%;
    }

    .contactUs-card {
        width: 95%;
    }
}