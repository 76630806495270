.addTempField-servise-legend {
    flex-basis: 70%;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin-bottom: 10px;
}

.addTempField-servise-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}
