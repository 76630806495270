.FG-container-pass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
}

.FG-card-pass {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
    border: 1px solid #C4C4C4;
    border-radius: 12px;
    padding: 40px;
}

.forgotPass_title {
    color: #333;
    font-family: "Public Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.forgotPass_enterEmail_typo {
    color: #333;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.FG-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 40px;
}

.forgotPass-loginButton {
    display: flex;
    align-self: flex-end;
    width: 'fit-content';
    padding: 12px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    color: white;
    background: var(--components-button-filled-background, #16536B);
}

.forgotPass-loginButton:hover {
    opacity: 0.8;
    background: var(--components-button-filled-background, #16536B);

}

@media(max-width: 1000px) {
    .FG-card-pass {
        width: 60%;
    }
}

@media(max-width: 600px) {
    .FG-card-pass {
        width: 90%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .forgotPass_title {
        font-size: 24px;
        line-height: 32px;
    }
}