.homeSevenPart-main-box {
    margin-top: 3.5%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 8%;
    width: 90%;
}

.homeSevenPart-image {
    width: 55%;
}

.homeSevenPart-typo-box {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    width: 38%;
}

.homeSixPart-typo-ehealth {
    color: #09C3CC;
    font-size: 40px;
    font-weight: 800;
    line-height: 52px;
}

.homeSevenPart-typo-aussi {
    color: #17536B;
    font-size: 40px;
    font-weight: 800;
    line-height: 52px;
}

.homeSevenPart-typo-dr-junior {
    margin-top: 4%;
    margin-left: 7%;
    color: #17536B;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    width: 37%;
    align-self: flex-start;
}

.homeSevenPart-typo-ehealth {
    font-weight: 800;
    color: #09C3CC;
    font-size: 40px;
}

.homeSevenPart-typo-img-box {
    display: flex;
    justify-content: flex-end;
}

@media(max-width: 1300px) {

    .homeSevenPart-main-box {
        display: flex;
        justify-content: space-around;
        justify-self: flex-end;
        margin-left: 0;
        width: 100%;
    }

    .homeSevenPart-typo-img-box {
        justify-content: space-around;
    }

    .homeSevenPart-typo-ehealth {
        font-size: 30px;
    }

    .homeSevenPart-typo-aussi {
        font-size: 30px;
    }

    .homeSevenPart-typo-dr-junior {
        width: 90%;
    }


}


@media(max-width: 900px) {
    .homeSevenPart-typo-ehealth {
        font-size: 20px;
        text-align: center;

    }

    .homeSevenPart-image {
        width: 60%;
        object-fit: contain;
    }

    .homeSevenPart-typo-aussi {
        font-size: 20px;
        text-align: center;

    }

    .homeSevenPart-typo-dr-junior {
        font-size: 12px;
        text-align: center;
    }
}

@media(max-width: 600px) {
    .homeSevenPart-typo-img-box {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .homeSevenPart-typo-box {
        width: 90%;
    }

    .homeSevenPart-image {
        width: 90%;
    }
}