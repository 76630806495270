.template-filtration-container {
    margin-top: 15px;
    margin-bottom: 15px;
}

.template-filtration-card {
    padding: 7px;
}

.template-filtration-header-box {
    display: flex;
    justify-content: space-between;
    color: #17536B;
}

.template-filtration-header-title {
    font-weight: 500;
    font-size: 16px;
}

.template-filtration-header-action {
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.template-filtration-footer-box {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-top: 10px;
}

.template-filtration-footer-action {
    color: #17536B;
    cursor: pointer;

}