.passwordReset_main_box {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 60%;
    border: 1px solid #C4C4C4;
    border-radius: 12px;
    padding: 40px;
}

.passwordReset_image {
    width: 200px;
    margin: 0 auto;
}

.passwordReset_title_typo_box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;

}

.passwordReset_title {
    color: #333;
    font-family: "Public Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
}

.passwordReset__tryAgain_box {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.passwordReset__typo {
    color: #333;
    text-align: center;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: center;
}

.password_successfully_reset {
    display: flex;
    width: 250px;
    padding: 12px 18px;
    justify-self: center;
    align-self: center;
    gap: 10px;
    border-radius: 12px;
    color: white;
    background: var(--components-button-filled-background, #16536B);
}

.password_successfully_reset:hover {
    opacity: 0.8;
    background: var(--components-button-filled-background, #16536B);
}

@media(max-width: 1000px) {
    .passwordReset_main_box {
        width: 90%;
    }
}

@media(max-width: 600px) {
    .passwordReset_main_box {
        padding-left: 20px;
        padding-right: 20px;
    }

    .passwordReset_title{
        font-size: 24px;
        line-height: 32px;
    }
}

