.RP-container-pass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
}

.RP-card-pass {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
    border: 1px solid #C4C4C4;
    border-radius: 12px;
    padding: 40px;
}

.RP_pass_title {
    color: #333;
    font-family: "Public Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.RP_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.RP_button_valider {
    display: flex;
    align-self: flex-end;
    width: 250px;
    padding: 12px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    color: white;
    background: var(--components-button-filled-background, #16536B);
}

.RP_button_valider:hover {
    opacity: 0.8;
    background: var(--components-button-filled-background, #16536B);
}

@media(max-width: 1000px) {
    .RP-card-pass {
        width: 80%;
    }
}

@media(max-width: 600px) {
    .RP-card-pass {
        width: 90%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .RP_pass_title{
        font-size: 24px;
        line-height: 32px;
    }
}