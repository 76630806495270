 .fieldType-fileInList-upload-box {
     border: 1px dashed #16536B;
     width: 100%;
     height: 168px;
     background-color: #F1F9FC;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     gap: 12px;
     cursor: pointer;
     border-radius: 12px;
 }