.copyFile-select-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.copyFile-actions-box {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 40px;
}