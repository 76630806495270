.register-page {
  background-color: #F6F6F6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.register-Box {
  width: 60%;
  margin: 0 auto;
}

.register-submitForm {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 25px;
}

.register-inputs {
  border: 1px #C2C2C2;
  border-radius: 8px;
}

.register-inputs .MuiInputLabel-root {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.register-inputs .MuiIconButton-root {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.recapatchaBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin: auto;
}

.register-Button {
  color: white;
  background-color: #17536B;
  border-radius: 10px;
}

.register-Button:hover {
  color: white;
  background-color: #17536B;
}

.loginLinkBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.loginLinkLabel {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #17536B;
  opacity: 70%;
  font-family: 'Roboto', sans-serif;
}

.loginLink {
  color: #09c3cc;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 16px;
}

@media(max-width: 650px) {
  .register-Box {
    width: 90%;
  }

  .loginLinkLabel {
    font-size: 12px;
    line-height: 12px;
  }

  .loginLink {
    font-size: 12px;
    line-height: 12px;
  }

}