.NUP_container_pass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
}

.NUP_card_pass {
    width: 60%;
}

.NUP_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.NUP_button_valider {
    background-color: #16536B;
    border: 1px solid #16536B;
    border-color: #16536B;
    color: white;
    border-radius: 4px;
}

.NUP_button_valider:hover {
    background-color: #16536B;
    border: 1px solid #16536B;
    border-color: #16536B;
    color: white;
}

@media(max-width: 600px) {
    .NUP_card_pass {
        width: 100%;
    }
}