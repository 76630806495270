.collaboration-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.collaboration-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.collaboration-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.collaboration-page-title-icon {
    color: #ffffff;
    width: 18px;
    height: 18px;
}

.collaboration-page-action-box {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.collaboration-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 6px;
    color: white;
    cursor: pointer;
}

.collaboration-responsive-card-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    color: white;
    border-radius: 7px;
    background-color: #17536B;
    padding: 5px;
}

.collaboration-card-resp-part {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.collaboration-card-actions {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-around;
}

.collaboration-table-titles {
    color: #1A2744;
    font-weight: 600;
}

.collaboration-details-cell {
    font-weight: bold;
    cursor: pointer;
}

.collaboration-icon-actions {
    color: white;
    cursor: pointer;
    background-color: #1b97c8;
    border-radius: 50%;
    font-size: 33px;
    padding: 5px;
}

.collaboration-TableRow{
    cursor: pointer;
}

.collaboration-TableRow:hover {
    background-color: #C4E9EB;
}

@media(max-width: 900px) {
    .collaboration-button-title {
        display: none;
    }
}

@media(max-width: 500px) {

    .collaboration-page-title {
        font-size: 12px;
    }
}