.homeFivePart-main-box {
    margin-top: 90px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.homeFivePart-arrow-typo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.homeFivePart-arrow-typo-box:hover .homeFivePart-arrow-icon {
    animation: slidein 0.7s forwards;
}

@keyframes slidein {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(10px);
    }
}

.homeFivePart-arrow-typo-box:hover .homeFivePart-typo-reseau {
    color: #09C3CC;
    cursor: default;
}

.homeFivePart-arrow-icon {
    width: 24px;
    height: 16px;
}

.homeFivePart-typo-reseau {
    width: 400px;
    height: 33px;
    font-size: 30px;
    line-height: 33px;
    color: #17536B;
}

.homeFivePart-typo-img-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.homeFivePart-reseauProf-img {
    width: 450px;
}

.homeFivePart-two-part-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;

}

.homeFivePart-two-part-box-reverse {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
    gap: 60px;
}

.homeFivePart-typo-part {
    width: 450px;
    height: 100px;
    font-size: 18px;
    line-height: 25px;
    color: #17536B;
}

.homeFivePart-typo-reverse-part {
    width: 450px;
    height: 100px;
    font-size: 18px;
    line-height: 25px;
    color: #17536B;
    text-align: end;
}

@media(max-width: 1000px) {
    .homeFivePart-two-part-box-reverse{
        gap: 20px;
    }

    .homeFivePart-two-part-box{
        gap: 20px;
    }

}

@media(max-width: 450px) {
    .homeFivePart-reseauProf-img {
        width: 350px;
    }

    .homeFivePart-typo-reseau {
        font-size: 20px;
    }

    .homeFivePart-arrow-typo-box {
        margin-left: 20%;
    }

    .homeFivePart-typo-part {
        width: 350px;
        font-size: 14px;
        text-align: center;
    }

    .homeFivePart-typo-reverse-part {
        width: 350px;
        font-size: 14px;
        text-align: center;
    }
    .homeFivePart-two-part-box{
        gap: 10px;
    }
    .homeFivePart-two-part-box-reverse{
        gap: 10px;
    }
    .homeFivePart-main-box{
        gap: 100px;
    }

}

@media(max-width: 350px) {
    .homeFivePart-reseauProf-img {
        width: 280px;
    }

    .homeFivePart-typo-reseau {
        font-size: 20px;
    }

    .homeFivePart-arrow-typo-box {
        margin-left: 30%;
    }

    .homeFivePart-typo-part {
        width: 250px;
        font-size: 12px;
        text-align: center;
    }

    .homeFivePart-typo-reverse-part {
        width: 250px;
        font-size: 12px;
        text-align: center;
    }

}