.discussion-container {
  height: 550px;
  background-color: #F3F3F3;
  border-radius: 12px;
  padding: 20px 40px;
}

.discussion-msg-box {
  display: flex;
  overflow-y: auto;
  overflow-x: clip;
  height: 450px;
}

.discussion-date-box {
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #8A898E;
}

.discussion-sender-header {
  display: flex;
  justify-content: flex-end;
}

.discussion-recipient-header {
  display: flex;
  justify-content: flex-start;
}

.discussion-sender-name {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #8A898E;
  letter-spacing: 0.5px;
}

.discussion-message {
  padding: 18px 9px;
  word-wrap: break-word;
  line-height: 24px;
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 6px;
  position: relative;
  border-radius: 15px;
}

.discussion-message-sender {
  color: white;
  background: #487E97;
}

.discussion-message-recipient {
  background: #E9E9EB;
  color: #000000;
}

.last-message {

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 25px;
  }
}

.discussion-message-sender.last-message:before {
  right: -7px;
  width: 20px;
  background-color: #487E97;
  border-bottom-left-radius: 16px;
}

.discussion-message-sender.last-message:after {
  right: -26px;
  width: 26px;
  background-color: #F3F3F3;
  border-bottom-left-radius: 10px;
}

.discussion-message-recipient.last-message:before {
  left: -7px;
  width: 20px;
  background-color: #E9E9EB;
  border-bottom-right-radius: 16px;
}

.discussion-message-recipient.last-message:after {
  left: -26px;
  width: 26px;
  background-color: #F3F3F3;
  border-bottom-right-radius: 10px;
}

.discussion-message-time {
  font-size: 12px;
  color: #8A898E;
}

.discussion_input .MuiOutlinedInput-root fieldset {
  border-color: #727179;
  border-radius: 12px;
}

.discussion_input .MuiOutlinedInput-root:not(.Mui-disabled):hover fieldset {
  border-color: #727179;
}

.discussion_input .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #727179;
}

.discussion-send_icon {
  font-size: xx-large;
  color: #727179;
  cursor: pointer;
}