.read-onetemp-action {
    display: flex;
    gap: 5%;
}

.read-onetemp-label {
    text-align: center;
    font-weight: bold;
    font-size: 25px;
}

.read-onetemp-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 10px;
}

.read-onetemp-back-btn:hover {
    color: white;
    background-color: #17536B;
    border-radius: 10px;
}

.read-onetemp-info-box {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.read-onetemp-field-info {
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
}

.read-onetemp-field-label {
    flex-basis: 20%;
}

.read-onetemp-grid-container {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 1%;
}

.read-onetemp-sections-box {
    display: flex;
    margin-top: 5px;
    padding: 7px;
    margin-bottom: 10px;
    background-color: #EEF0F2;
    gap: 5px;
}

.read-onetemp-sections-style {
    display: flex;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    padding: 7px;
    color: #17536B;
    font-size: 14px;
    cursor: pointer;
}

.read-onetemp-grid-box {
    margin: 2%;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
}

.read-onetemp-sections-box {
    display: flex;
    margin-top: 5px;
}

.read-onetemp-fields-box {
    display: flex;
    margin-top: 1%;
    align-items: center;
    border: 1px solid #34697E;
    padding: 5px;
    border-radius: 7px;
}

.read-onetemp-for-list-box {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;
    background-color: rgba(145, 158, 171, 0.12);
    width: 100%;
}

.read-onetemp-for-list-label-type {
    display: flex;
    align-items: center;
    border: 1px solid #34697E;
    padding: 5px;
    border-radius: 7px;
    margin-top: 5px;
}

.read-onetemp-for-nolist-label-type {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 5px;
}

.read-onetemp-for-list-label {
    width: 30%;
    background-color: #84E1E6;
    padding: 2px;
    height: fit-content;
    margin-right: 10px;
    border-radius: 5px;
}

.read-onetemp-for-list-type {
    flex-basis: 20;
}

.read-onetemp-select-multiselect-box {
    width: 50%;
}


.read-onetemp-response-occardion {
    display: flex;
    align-items: center;
    width: 70%;
}

.read-onetemp-response-occardion-column {
    display: flex;
    justify-content: space-between;
    gap: 20%;
}

.readTemp-btn-container {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 6px;
    color: #ffffff;
    cursor: pointer;
}

.readTemp-headerBtn-icon {
    font-size: 20px;
}

.readTemp-btn-title {
    font-size: 12px;
}

.readTemp-top-bar {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
    justify-content: space-between;
    color: #ffffff;
}

.readTemp-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.readTemp-action-button-box {
    display: flex;
    gap: 5px;
    background-color: #17536B;
    align-items: center;
    height: 35px;
    justify-content: flex-end;
    padding: 7px;
    border-radius: 7px;
    height: 44px;
    width: 100%;
}

@media(max-width: 600px) {
    .read-onetemp-field-label {
        flex-basis: 50%;
        font-size: 12px;
    }

    .read-onetemp-field-response {
        font-size: 12px;
        flex-basis: 80%;
    }

    .read-onetemp-for-list-label-type {
        align-items: flex-start;
        flex-direction: column;
    }

    .read-onetemp-for-nolist-label-type {
        align-items: flex-start;
        flex-direction: column;
    }

    .read-onetemp-for-list-label {
        width: 100%;
        text-align: center;
    }

    .read-onetemp-for-list-type {
        width: 100%;
        text-align: center;
    }

    .read-onetemp-response-occardion {
        justify-content: center;
        width: 100%;
    }

    .read-onetemp-response-occardion-column {
        display: flex;
        flex-direction: column;
    }

    .readTemp-headerBtn-icon {
        font-size: 16px;
    }
}

@media(max-width: 500px) {
    .read-onetemp-label {
        font-weight: bold;
        font-size: 20px;
        flex-basis: 90%;
    }

    .read-onetemp-back-btn {
        font-size: 11px;
    }
}