.collaborationInfo-main-box {
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.collaborationInfo-main-page-header-box {
    display: flex;
    gap: 10px;
    margin-bottom: 2%;
}

.collaborationInfo-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.collaborationInfo-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.collaborationInfo-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.collaborationInfo-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.collaborationInfo-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    gap: 6px;
    color: white;
    cursor: pointer;
}

.collaborationInfo-plus-icon {
    font-size: 20px;
}

.collaborationInfo-actions-box {
    display: flex;
    gap: 10px;
    align-items: center;
}

.collaborationInfo-details-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.collaborationInfo-labels {
    font-size: 20px;
    font-weight: 400;
    color: #717171;
    width: 100%;
    text-align: center;
}

.collaborationInfo-values-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.5;
}

.collaborationInfo-values {
    font-size: 18px;
    font-weight: 400;
    color: #1A2744;
    width: 100%;
    text-align: center;
}

.collaborationInfo-loading {
    position: absolute;
    top: 55%;
    margin-left: 40%;
    size: 5rem;
}

.collaborationInfo-institutionsData-box {
    display: flex;
    gap: 10px;
    width: 100%;

}

.collaborationInfo-accordion-main,
.collaborationInfo-accordion-drs {
    &.MuiAccordion-root.Mui-expanded {
        margin: 0;
    }
}

.collaborationInfo-accordion-box {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    padding: 50px;

}

.collaborationInfo-accordion-sp-exp-box {
    display: flex;
    width: 50%;
    gap: 10px;
}

.collaborationInfo-accordion-main {
    width: 100%;
    background-color: transparent !important;
}

.collaborationInfo-accordion-drs {
    width: 50%;
    background-color: transparent !important;

}

.collaborationInfo-accordion-header {

    background-color: #ebeef4;
    min-height: 30px !important;
    max-height: 30px !important;
    height: 30px;

}

.collaborationInfo-accordion-header .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    left: 2px;
}

.collaborationInfo-accordion-header .MuiAccordionSummary-content {
    font-size: 14px;
    font-weight: 400;
    color: #1A2744;
    line-height: 16px;
    height: 30px;
    display: flex;
    align-items: center;
    margin: 0px 0px 0px 10px;
    min-height: 30px;
    max-height: 30px;
    height: 30px;
}

.collaborationInfo-accordion-details {
    display: flex;
    flex-direction: column;
    padding: 0px;
    overflow-y: auto;
    max-height: 250px;
}

.collaborationInfo-accordion-values {
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid #ebeef4;
}

.collaborationInfo-accordion-values.selected {
    background-color: #749fe8;
}

.collaborationInfo-accordion-values:Hover {
    background-color: #749fe8;
}

.collaborationInfo-accordion-drs-values {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ebeef4;

}

@media(max-width: 900px) {
    .collaborationInfo-button-title {
        display: none;
    }

    .collaborationInfo-accordion-box {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding: 0px;
        padding-top: 50px;
    }

    .collaborationInfo-accordion-sp-exp-box {
        width: 100%;
    }

    .collaborationInfo-accordion-drs {
        width: 100%;
    }
}

@media(max-width: 600px) {

    .collaborationInfo-labels {
        font-size: 15px;
    }

    .collaborationInfo-values {
        font-size: 16px;
    }

    .collaborationInfo-plus-icon {
        font-size: 16px;
    }

    .collaborationInfo-accordion-drs-emails {
        display: none;
    }

    .collaborationInfo-accordion-drs-values {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: 1px solid #ebeef4;

    }
}