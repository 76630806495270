.footer-main-box {
display: flex;
justify-content: center;
align-items: center;
background-color: #0C2A36;
width: 100%;
}

.footer-sub-container{
width: 70%;
display: flex;
justify-content: center;
align-items: center;
height:500px; 
}

.footer-image-container{
width: 48%;
}

.footer-logo{
width: 50%;
}

.linkedInIcon-logo{
width: 50%;
}
.ImeahShort-logo{
width: 100%;
}

.footer-link-list{
display: flex;
flex-direction: column;
flex-basis: 33%;
gap: 35px;
}

.footer-link{
text-decoration: none;
color: white;
font-weight: 500;
font-size: 12px;
line-height: 14px;
}

.footer-text{
color: #84E1E6;
size: 12px;
line-height: 20px;
font-weight: 400;
}

.footer-right-container{
display: flex;
flex-direction:column;
align-items: center;
gap: 35px;
}

@media(max-width: 900px) {

.footer-sub-container{
width: 70%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height:fit-content; 
padding: 20px;
gap: 35px;
}

.footer-logo{
width: 100%;
}

.linkedInIcon-logo{
width: 30%;
}

}