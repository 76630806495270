.homeElevenPart-main-box {
margin-top: 3.5%;
display: flex;
justify-content: space-around;
align-items: center;
width: 90%;
margin: 3.5% auto;
gap: 5%;
min-height: 400px;
}

.homeElevenPart-left-box {
display: flex;
flex-direction: column;
width: 48%;
gap: 35px;
}

.homeElevenPart-Qui-sommes-nous {
color: #17536B;
font-size: 40px;
font-weight: 800;
line-height: 52px;
}

.homeElevenPart-Desc-text {
color: #17536B;
font-size: 16px;
font-weight: 400;
line-height: 22.4px;
}

.homeElevenPart-image-container{
width: 48%;
}

.homeElevenPart-image {
width: 100%;
}

@media(max-width: 900px) {

.homeElevenPart-left-box {
display: flex;
flex-direction: column;
width: 48%;
gap: 25px;
}
    
.homeElevenPart-Qui-sommes-nous {
color: #17536B;
font-size: 25px;
font-weight: 800;
line-height: 52px;
}
    
.homeElevenPart-Desc-text {
color: #17536B;
font-size: 12px;
font-weight: 400;
line-height: 22.4px;
}

}

@media(max-width: 600px) {
.homeElevenPart-main-box {
margin-top: 3.5%;
display: flex;
flex-direction: column;
align-items: center;
width: 90%;
margin: 3.5% auto;
gap: 35px;
}
    
.homeElevenPart-left-box {
display: flex;
flex-direction: column;
width: 100%;
gap: 25px;
}
    
.homeElevenPart-Qui-sommes-nous {
color: #17536B;
font-size: 35px;
font-weight: 800;
line-height: 52px;
}
    
.homeElevenPart-image-container{
width: 100%;
}

}