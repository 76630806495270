.MeetingReceiverBox{
display: flex;
flex-direction: row;
gap: 7px;
align-items: center;
}

.editMeetingAddReceiver{
display: flex;
justify-content: space-between;
background-color: #17536B;
color: white;
padding: 5px;
border-radius: 9px;
align-items: center;
margin-bottom: 10px;
}

.editMeetingReceiverList{
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 10px;
}