.staticData_accordion {
    margin: 20px 0px;
    padding: 0px;
    position: static;
    border-bottom: 2px solid #AEAEB2;
}

.staticData_accordion_summary_box {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 28px;
}

.staticData_patient_avatar {
    border-radius: 5px;
    width: 80px;
    height: 85px;
}

.staticData_first_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.staticData_second_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.staticData_item_box {
    display: flex;
    align-items: center;
    gap: 3px;
}

.staticData_label {
    color: #000;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.staticData_value {
    color: #000;
    font-family: "Public Sans";
    /* text-transform: lowercase; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

/* .staticData_value::first-letter {
    text-transform: uppercase;
} */

.staticData_accordion_details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 28px;
    padding: 0px 0px 20px 0px;
}

.staticData_delete_icon {
    color: #FF0000;
    width: 20px;
    height: 20px;
}

.staticData_upload_icon {
    color: #49454F;
    width: 20px;
    height: 20px;
}

.staticData_edit_icon {
    color: #727179;
    width: 20px;
    height: 20px;
}

.staticData_text_Button {
    color: #727179;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.25px;
}

@media(max-width: 600px) {

    .staticData_accordion {
        margin: 15px 0px;
        border: none;
        box-shadow: none;
    }

    .staticData_patient_avatar {
        width: 85px;
        height: 95px;
    }

    .staticData_first_section {
        gap: 18px;
    }

    .staticData_second_section {
        flex-direction: row;
        gap: 28px;
    }

    .staticData_accordion_summary_box {
        flex-direction: column;
        gap: 20px;
    }

    .staticData_accordion_details {
        gap: 20px;
    }

}