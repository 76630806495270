.policy-dialog {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.policy-section {
    margin-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
}

.policy-checkbox {
    margin-right: 10px;
    flex-shrink: 0;
}

.subTitle {
    font-size: 16px;
    font-weight: 600;
    color: #444;
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 5px;
}

.policy-list {
    padding-left: 0;
}

.policy-item {
    padding: 2px 0;
}

.policy-text {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
}
