
.read-institution-table-titles {
    color: #1A2744;
    font-weight: 600;
}

.read-institution-responsive-card-details {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: white;
    border-radius: 7px;
    background-color: #17536B;
    padding: 5px;
}

.read-institution-responsive-card-data {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.read-institution-card-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.read-institution-card-icons {
    color: #ffffff;
    cursor: pointer;
}