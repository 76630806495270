.education-dialog-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.education-date-box {
    display: flex;
    gap: 10px;
}

.education-date-field {
    width: 100%;
}

.education-dialog-typo{
    font-size: 14px;
    color: gray;
}

@media(max-width: 450px){
    .education-date-box {
        flex-wrap: wrap;
    }
}