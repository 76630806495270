.dashboard-widgetSummary-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.dashboard-medical-statistics-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 15px;
}

.dashboard-pie-chart-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

@media(max-width: 800px) {

    .dashboard-widgetSummary-box {
        grid-template-columns: repeat(2, 1fr);
    }

    .dashboard-medical-statistics-box {
        grid-template-columns: repeat(1, 1fr);
    }

}

@media(max-width: 600px) {
    .dashboard-widgetSummary-box {
        grid-template-columns: repeat(1, 1fr);
    }

}