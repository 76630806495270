.error_dialog_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.error_dialog_icon {
    width: 48px;
    height: 48px;
    color: #f44336
}

.error_dialog_title {
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #721c24;
}

.error_dialog_button {
    background-color: transparent;
    color: #f44336;
    border: 1px solid #f44336;
    border-radius: 4px;
    width: fit-content;
    height: auto;
}

.error_dialog_button:hover {
    background-color: transparent;
    color: #721c24;
    border: 1px solid #721c24;
}