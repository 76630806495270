.add-templatelistfield-formcontrol {
    margin-top: 10px;
}

.add-templatelistfield-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
    background-color: #17536B;
    border-radius: 10px;
}