.userCv-main-box {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
}

.userCv-main-card {
    position: relative;
    width: 100%;
    padding: 5%
}

.userCv-main-typo {
    color: #34697E;
    position: absolute;
    top: 5%;
    left: 5%;
    font-size: 22px;
    font-weight: bold;
}

.userCv-typo-uni {
    font-weight: bold;
    font-size: 20px;
}

.userCv-typo-degree {
    font-size: 15px;
}

.userCv-typo-date {
    width: fit-content;
    font-size: 13px;
    color: gray;
}

.userCv-date-box {
    display: flex;
    flex-direction: row;
}

.userCv-typo-desc {
    margin-top: 2%;
    font-size: 14px;
    font-family: sans-serif;
}

.userCv-data-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
}

.userCv-box-border-bottom {
    position: relative;
    padding-bottom: 15px;
    border-bottom: 0.2px solid rgb(197, 190, 190);
}

.userCv-actions-box {
    position: absolute;
    font-size: 25px;
    right: 5%;
    top: 5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.userCv-actions-add-icon {
    position: absolute;
    right: 5%;
    top: 5%;
    font-size: 35px;
    cursor: pointer;
}

.add-education-current-post-box{
    display: flex;
    align-items: center;
    gap: 3%;
    /* justify-content: space-between; */
}

@media(max-width: 900px) {

    .userCv-typo-summary {
        margin-top: 3%;
    }
}

@media(max-width: 500px) {
    .userCv-typo-summary {
        margin-top: 7%;
    }

    .userCv-data-box {
        margin-top: 50px;
    }

    .userCv-typo-desc {
        font-size: 12px;
    }

    .userCv-typo-uni {
        font-size: 15px;
    }

    .userCv-typo-degree {
        font-size: 13px;
    }

    .userCv-typo-date {
        font-size: 11px;
    }
}

@media(max-width: 500px) {
    .userCv-typo-summary {
        margin-top: 10%;
    }
}