.addInstitution-actions-box {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 40px;
}

.addInstitution-back-btn {
    color: white;
    background-color: #17536B;
    border-radius: 7px;
    font-size: 44px;
    padding-left: 9px;
    cursor: pointer;
}

.addInstitution-main-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
}

.addInstitution-page-title-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
}

.addInstitution-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}
