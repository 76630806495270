.instututions-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #17536B;
    border-radius: 7px;
    padding: 7px;
    margin-bottom: 10px;
    width: 100%;
    height: 44px;

}

.instututions-page-title-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10%;
    width: 70%;
}

.instututions-page-title-icon {
    color: #ffffff;
    width: 16px;
    height: 16px;
}

.instututions-page-title-icon-box {
    display: flex;
    gap: 5px;
    align-items: center;
}


.instututions-page-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
}

.instututions-page-search-input {
    border: #ffffff 1px solid;
    border-radius: 5px;
    padding: 2px;
    color: #ffffff;
}

.instututions-page-action-box {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.instututions-page-header-button {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: white;
    cursor: pointer;
}

.instututions-page-title-icon {
    font-size: 20px;
    cursor: pointer;
    color: #ffffff;
}

.instututions-page-filter-flag-item {
    display: flex;
    align-items: center;
    gap: 3px;
    background-color: #17536B;
    padding: 5px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

@media(max-width: 500px) {

    .instututions-page-title {
        font-size: 12px;
    }
}