.selectSpeciality-mainBox {
    display: flex;
    flex-direction: column;
    gap: 55px;
}

.select-sp-exp-expertise-list {
    width: 100%;
}

.select-sp-exp-doctors-table {
    margin-top: 20px;
    padding: 10px;
    border-radius: 25px;
    background-color: rgba(145, 158, 171, 0.12);
}

.select_sp_exp-emptyDoctorWarningBox {
    height: 230px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #eff1f6;
    border-radius: 5px;
}

.select_sp_exp-emptyDoctorWarningTypography {
    width: 45%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #828282;
}

.select_sp_exp-table-row {
    border-bottom: 2px solid #17536B;
}

.select_sp_exp-table-titles {
    color: #1A2744;
}

.select_sp_exp-table-cell {
    padding-left: 1.5%;
    height: 50px;
    padding: 0px;
    padding-left: 15px;
}

.select_sp_exp_accountIcon {
    color: #16536B;
    cursor: pointer;
    border-radius: 50%;
    font-size: 28px;
    padding: 5px;
}

.select_sp_exp-responsive-card-details {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    color: white;
    border-radius: 7px;
    padding: 5px;
}

.select_sp_exp-card-resp-part {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.selectSpeciality-requiredNote{
    color: gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
}

.select_sp_exp_viewProfile{
    color: #16536B;
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
}

.select_sp_exp_viewProfileBox{
    display: flex;
    align-items: center;
    gap: 2px;
}

.select_sp_exp_responsive_card_details {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: 150px;
    border-radius: 12px;
    background-color:  #ecf0f6;
    padding: 12px;
    position: relative;
}

.select_sp_exp_responsive_cards_box{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
}

.select_sp_exp_responsive_card_title_response_box {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.select_sp_exp_responsive_card_title {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #6C757D;
}

.select_sp_exp_responsive_card_response {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #343A40;
}

.select_sp_exp_responsive_card_radio{
    position: absolute;
    top: 10px;
    right: 10px;
}