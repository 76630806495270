.medicalFileFields_info_container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 80%
}

.medicalFileFields_Field_label {
    /* text-transform: lowercase; */
    font-family: 'Public Sans', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    color: #000000;
}

/* .medicalFileFields_Field_label::first-letter {
    text-transform: uppercase;
} */

.medicalFileFields_listField_label {
    text-transform: lowercase;
    font-family: 'Public Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    color: #49454F
}

.medicalFileFields_listField_label::first-letter {
    text-transform: uppercase;
}

.medicalFileFields_listField_container {
    background-color: #F9F9F9;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    border-radius: 12px;
    border: 1px solid #E8E8E8;
}

/* .medicalFileFields_field_divider {
    height: 2px;
    background-color: #d0d0d078;
} */

@media(max-width: 600px) {

    .medicalFileFields_info_container {
        width: 100%
    }

    .medicalFileFields_Field_label {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    .medicalFileFields_listField_label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

}